import { Outlet } from "react-router-dom";
import { SimulationProvider } from "@/providers/Simulation/provider";
import { Header } from "@/components/header";

export function DealLayout() {
  return (
    <SimulationProvider>
      <Header>
        <h1>Negociar dívidas</h1>
      </Header>

      <Outlet />
    </SimulationProvider>
  );
}
