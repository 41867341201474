import { FormLogin } from "./login-form";
import styles from "./hero.module.css";

export function Hero() {
  return (
    <section id="inicio" className={styles.wrapper}>
      <div className={styles.inner_wrapper}>
        <div className={styles.banner_wrapper}>
          <div className={styles.image_wrapper}>
            <img src="/images/selo_november.webp" alt="Selo" />
          </div>
          <div className={styles.box_wrapper}>
            <header className={styles.discount}>
              <p>OFERTAS</p>
              <p>POR TEMPO</p>
              <p>LIMITADO</p>
              <p>95%</p>
              <p>DESCONTO</p>
            </header>
            <p>*Oferta disponível conforme a condição do credor</p>
          </div>
        </div>

        <FormLogin />
      </div>
      <img src="/images/stripe.webp" alt="Faixa amarela Negociação Havan" />
      <img src="/images/stripe.webp" alt="Faixa amarela Negociação Havan" />
      <img src="/images/stripe.webp" alt="Faixa amarela Negociação Havan" />
      <img src="/images/stripe.webp" alt="Faixa amarela Negociação Havan" />
    </section>
  );
}
