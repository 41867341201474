import { forwardRef, type PropsWithChildren } from "react";
import { Overlay as Primitive } from "@radix-ui/react-dialog";
import classes from "./dialog_overlay.module.css";

export const Overlay = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children, ...rest }, ref) => (
    <Primitive className={classes.wrapper} ref={ref} {...rest}>
      {children}
    </Primitive>
  )
);

Overlay.displayName = "Overlay";
