import { Link } from "@/components/core";
import styles from "./why_negociate.module.css";

export function WhyNegociate() {
  return (
    <section className={styles.wrapper}>
      <div className={styles.content_wrapper}>
        <div className={styles.content}>
          <h2>Por que negociar com a Havan?</h2>
          <div className={styles.box}>
            <div>
              <h3>Acordo personalizado Havan</h3>
              <p>
                Aqui você negocia direto com a gente, oferecemos a melhor oferta
                para negociar sua dívida, com condições que cabem no seu bolso
              </p>
            </div>
            <div>
              <h3>Nome limpo</h3>
              <p>
                Seu nome limpo em até 5 dias após o pagamento da primeira
                parcela, não perca essa oportunidade
              </p>
            </div>
            <div>
              <h3>Pague com PIX</h3>
              <p>
                Além da opção de pagar via boleto, você pode quitar os débitos
                via PIX. Mais rapidez pra você!
              </p>
            </div>
          </div>
        </div>

        <img
          src="/images/havan-negociar-divida.webp"
          alt="Homem no sofá na sala de casa, utilizando o celular para negociar sua dívida com a Havan"
        />
      </div>
      <Link to="negociar" size="large">
        Quero negociar minha dívida
      </Link>
    </section>
  );
}
