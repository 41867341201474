import { Close, Portal, Root, Trigger } from "@radix-ui/react-dialog";
import { Content } from "./content";
import { CloseIcon } from "./close-icon";
import { Description } from "./description";
import { Overlay } from "./overlay";
import { Title } from "./title";

export const Dialog = {
  Close,
  CloseIcon,
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
};
