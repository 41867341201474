import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  Route,
} from "react-router-dom";
import { Layout } from "./layouts";
import { DealLayout } from "./layouts/deal";
import { LandingPage } from "./pages/landing-page";
import { Pagamento } from "./pages/negociar/payment";
import { LayoutPagamento } from "./layouts/payment";
import { Negociar } from "./pages/negociar";
import { Protected } from "./auth/layout";
import { PublicLayout } from "./layouts/public";
import { PrivateLayout } from "./layouts/private";

const SemDividas = lazy(() => import("./pages/sem-dividas"));
const PoliticaDePrivacidade = lazy(
  () => import("./pages/politica-de-privacidade")
);
const RenegociarHome = lazy(() => import("./pages/negociar/home"));

export default createBrowserRouter(
  createRoutesFromChildren(
    <Route Component={Layout}>
      <Route Component={PublicLayout}>
        <Route index Component={LandingPage} />
        <Route
          path="politica-de-privacidade"
          Component={PoliticaDePrivacidade}
        />
      </Route>

      <Route Component={Protected}>
        <Route Component={PrivateLayout}>
          <Route path="sem-dividas" Component={SemDividas} />
          <Route path="pagamento" Component={LayoutPagamento}>
            <Route index Component={Pagamento} />
          </Route>
        </Route>
      </Route>

      <Route path="negociar" Component={PrivateLayout}>
        <Route index Component={RenegociarHome} />
        <Route path="acordo" Component={Protected}>
          <Route Component={DealLayout}>
            <Route index Component={Negociar} />
          </Route>
        </Route>
      </Route>
    </Route>
  )
);
