import { Minus, Plus } from "@havan/paladinos-react-icons";
import { Badge } from "@/components/core";
import { Spinner } from "@/components/spinner";
import { useSimulation } from "@/providers/Simulation/hook";
import { formatMoney } from "@/utils/formatters";
import classes from "./installment_chooser.module.css";

interface Props {
  isLoading?: boolean;
  hasErrors?: boolean;
}

export function InstallmentChooser({ isLoading, hasErrors }: Props) {
  const { installments, installmentOptions, setInstallments } = useSimulation();

  const canChoose = !isLoading || !hasErrors;

  const canUseShortcuts =
    canChoose &&
    !!installmentOptions &&
    installmentOptions.parcelamento.length > 2;

  const lowestInstallment = installmentOptions?.parcelamento?.length
    ? Math.min(...installmentOptions?.parcelamento.map((item) => item.parcelas))
    : undefined;

  const largestInstallment = installmentOptions?.parcelamento
    ? Math.max(...installmentOptions?.parcelamento.map((item) => item.parcelas))
    : undefined;

  const averageInstallment =
    lowestInstallment &&
    largestInstallment &&
    largestInstallment - lowestInstallment > 2
      ? Math.ceil((lowestInstallment + largestInstallment) / 2)
      : undefined;

  const canDecreaseInstallment =
    !lowestInstallment || installments <= lowestInstallment;

  const canIncreaseInstallment =
    !largestInstallment || installments >= largestInstallment;

  const installmentValue =
    installmentOptions?.parcelamento.find(
      (parcela) => parcela.parcelas === installments
    )?.valorParcela ?? 0;

  return (
    <div className={classes.wrapper} data-loading={isLoading}>
      <p>Valor restante parcelado em</p>
      <div className={classes.installment}>
        <button
          onClick={() => setInstallments((state) => state - 1)}
          disabled={canDecreaseInstallment}
        >
          <Minus />
        </button>
        <p>
          {canChoose && (
            <span>
              {installments - 1}x de {formatMoney(installmentValue)}
            </span>
          )}
        </p>
        <button
          onClick={() => setInstallments((state) => state + 1)}
          disabled={canIncreaseInstallment}
        >
          <Plus />
        </button>
      </div>
      {canUseShortcuts && (
        <div className={classes.shortcut}>
          {lowestInstallment && (
            <Badge onClick={() => setInstallments(lowestInstallment)}>
              {lowestInstallment - 1}x
            </Badge>
          )}

          {averageInstallment && (
            <Badge onClick={() => setInstallments(averageInstallment)}>
              {averageInstallment - 1}x
            </Badge>
          )}

          {largestInstallment && (
            <Badge onClick={() => setInstallments(largestInstallment)}>
              {largestInstallment - 1}x
            </Badge>
          )}
        </div>
      )}
      <div className={classes.loader}>
        <Spinner />
      </div>
    </div>
  );
}
