import type { HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import classes from "./box.module.css";

type RootProps = PropsWithChildren &
  Pick<HTMLAttributes<HTMLDivElement>, "className">;

function Root({ children, className }: RootProps) {
  const rootClasses = classNames(className, classes.root);

  return <div className={rootClasses}>{children}</div>;
}

function Header({ children }: PropsWithChildren) {
  return <h2 className={classes.header}>{children}</h2>;
}

export const Box = { Root, Header };
