import { Fragment, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PrivateNavbar } from "@/components/layouts/private-navbar";
import { Fallback } from "./fallback";

export function PrivateLayout() {
  return (
    <Fragment>
      <PrivateNavbar />
      <Suspense fallback={<Fallback />}>
        <Outlet />
      </Suspense>
    </Fragment>
  );
}
