import type { InputHTMLAttributes } from "react";
import { CheckCircle, Close, Info } from "@havan/paladinos-react-icons";
import classes from "./input.module.css";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  isSuccess?: boolean;
  isError?: boolean;
  isMoney?: boolean;
  helperTextType?: "default" | "success" | "error";
  helperText?: string;
}

export function Input({
  label,
  isSuccess,
  isError,
  isMoney,
  helperText,
  helperTextType = "default",
  ...rest
}: Props) {
  return (
    <fieldset
      className={classes.wrapper}
      data-success={isSuccess}
      data-error={isError}
    >
      <label htmlFor={label}>{label}</label>
      <div className={classes.content}>
        {isMoney && <span className={classes.currency}>R$</span>}
        {isSuccess && !isError && <CheckCircle />}
        {isError && !isSuccess && <Close />}
        <input {...rest} />
      </div>
      {helperText && (
        <div className={classes.helper}>
          <Info />
          <p>{helperText}</p>
        </div>
      )}
    </fieldset>
  );
}

Input.displayName = "Input";
