import { Spinner } from "@/components/spinner";
import classes from "./fallback.module.css";

export function Fallback() {
  return (
    <div className={classes.wrapper}>
      <Spinner />
    </div>
  );
}
