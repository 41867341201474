import { useLayoutEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LogoLettering, Menu } from "@havan/paladinos-react-icons";
import styles from "./navbar.module.css";

const HASH_BENEFITS = "#beneficios";
const HASH_DOUBTS = "#duvidas";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  function handleScroll() {
    const { hash } = window.location;

    const scrollTo = document.getElementById(hash?.replace("#", ""));

    if (!scrollTo) return;

    window.scrollTo({
      top: scrollTo.offsetTop - 100,
      behavior: "smooth",
    });
  }

  function onClickMenuItem(hash?: string) {
    setIsMenuOpen(false);

    if (hash) return navigate({ pathname: "/", hash });

    navigate("/");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useLayoutEffect(() => handleScroll(), [window.location?.hash]);

  return (
    <nav className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.logo_wrapper}>
          <div className={styles.logos}>
            <LogoLettering />
          </div>
          <button>
            <label htmlFor="toggle">
              <Menu />
            </label>
          </button>
        </div>
        <input
          id="toggle"
          type="checkbox"
          checked={isMenuOpen}
          onChange={(event) => setIsMenuOpen(event.target.checked)}
          hidden
        />
        <div className={styles.links_wrapper}>
          <button id="button-inicio" onClick={() => onClickMenuItem()}>
            Início
          </button>
          <button
            id="button-beneficios"
            onClick={() => onClickMenuItem(HASH_BENEFITS)}
          >
            Benefícios
          </button>
          <button
            id="button-duvidas"
            onClick={() => onClickMenuItem(HASH_DOUBTS)}
          >
            Dúvidas
          </button>
          <Link id="renegociar-divida" to={{ pathname: "/negociar" }}>
            Negociar dívida
          </Link>
        </div>
      </div>
    </nav>
  );
}
