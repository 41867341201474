import { Fragment, useState } from "react";
import { AxiosError } from "axios";
import {
  CalendarPositive,
  Coin,
  Copy,
  Download,
  IllustrationBillDebtMobile,
} from "@havan/paladinos-react-icons";
import { Pagamento } from "@/services/pagamento";
import { Boleto } from "@/services/pagamento/types";
import { Spinner } from "@/components/spinner";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { Button, Dialog, Link } from "@/components/core";
import classes from "./payment_commom.module.css";

export function ModalBoleto() {
  const [boleto, setBoleto] = useState<Boleto>();
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const alert = useAlert();

  function fetchBoleto() {
    setIsFetching(true);
    Pagamento.boleto()
      .then((response) => setBoleto(response.data.result.boletos[0]))
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response?.data.errors) {
          const { errors } = error.response.data;
          errors.forEach((message) => {
            alert.alert("Boleto", message);
          });
        }
        setIsOpen(false);
      })
      .finally(() => setIsFetching(false));
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <Button
          id="abre-modal-boleto"
          variant="secondary"
          color="positive"
          onClick={() => fetchBoleto()}
          size="large"
        >
          Boleto
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Title>Pagar com Boleto</Dialog.Title>
          <div className={classes.instruction_wrapper}>
            {isOpen && !!boleto && !isFetching ? (
              <Fragment>
                <div className={classes.instruction}>
                  <IllustrationBillDebtMobile />
                  <p>
                    Copie e cole o código de barras do boleto no seu aplicativo
                    de Banco
                  </p>
                </div>
                <div className={classes.actions}>
                  <Link
                    id="download-pdf-boleto"
                    variant="secondary"
                    to={`${boleto?.urlBoleto}`}
                    target="_blank"
                    rel="noreferrer"
                    size="large"
                  >
                    <Download /> Baixar Boleto em PDF
                  </Link>
                  <Button
                    id="copia-codigo-boleto"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        boleto?.linhaDigitavelBoleto ?? ""
                      )
                    }
                    size="large"
                  >
                    <Copy /> Copiar código do Boleto
                  </Button>
                </div>
                <div className={classes.payment_info}>
                  <div className={classes.payment_info_line}>
                    <div>
                      <Coin />
                      <p>Valor</p>
                    </div>
                    <p>{boleto?.valorBoleto}</p>
                  </div>
                  <div className={classes.payment_info_line}>
                    <div>
                      <CalendarPositive />
                      <p>Vencimento</p>
                    </div>
                    <p>{boleto?.dataVencimentoBoleto}</p>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Spinner />
            )}
          </div>

          <Dialog.CloseIcon aria-label="Close" />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
