import type { ButtonHTMLAttributes } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import classes from "./button.module.css";

export type ButtonColor = "default" | "positive" | "negative" | "white";
export type ButtonSize = "small" | "medium" | "large";
export type ButtonVariant = "primary" | "secondary" | "ghost";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  variant?: ButtonVariant;
  size?: ButtonSize;
  spinner?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      color = "default",
      variant = "primary",
      size = "medium",
      spinner,
      children,
      className,
      ...rest
    },
    ref
  ) => {
    const buttonClasses = classNames(className, classes.base_button, {
      [classes.color_default]: color === "default",
      [classes.color_positive]: color === "positive",
      [classes.color_negative]: color === "negative",
      [classes.color_white]: color === "white",
      [classes.variant_primary]: variant === "primary",
      [classes.variant_secondary]: variant === "secondary",
      [classes.variant_ghost]: variant === "ghost",
      [classes.size_small]: size === "small",
      [classes.size_medium]: size === "medium",
      [classes.size_large]: size === "large",
      [classes.spinner]: spinner,
    });

    return (
      <button ref={ref} className={buttonClasses} {...rest}>
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";
