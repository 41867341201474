import { forwardRef, type PropsWithChildren } from "react";
import { Content as Primitive } from "@radix-ui/react-dialog";
import classes from "./dialog_content.module.css";

export const Content = forwardRef<HTMLDivElement, PropsWithChildren>(
  ({ children, ...rest }, ref) => (
    <Primitive className={classes.wrapper} ref={ref} {...rest}>
      {children}
    </Primitive>
  )
);

Content.displayName = "Content";
