import { Loader } from "@/assets/icons/Loader";
import classes from "./spinner.module.css";

export function Spinner() {
  return (
    <div className={classes.wrapper}>
      <Loader />
    </div>
  );
}
