import type { SelectProps } from "@radix-ui/react-select";
import {
  Content,
  Icon,
  Item,
  ItemText,
  ItemIndicator,
  Portal,
  Root,
  Trigger,
  Value,
  Viewport,
} from "@radix-ui/react-select";

import classes from "./select.module.css";
import { ChevronDown } from "@havan/paladinos-react-icons";

export interface Item {
  name: string;
  value: string;
}

interface Props extends SelectProps {
  label: string;
  itens: Item[];
}

export function Select({ children, label, itens, ...props }: Props) {
  return (
    <div className={classes.wrapper}>
      <label>{label}</label>
      <Root {...props}>
        <Trigger className={classes.trigger}>
          <Value />

          <Icon asChild>
            <ChevronDown />
          </Icon>
        </Trigger>
        <Portal>
          <Content
            className={classes.content}
            position="popper"
            sideOffset={12}
          >
            <Viewport>
              {itens.map(({ name, value }) => (
                <Item key={value} value={value} className={classes.item}>
                  <ItemIndicator />
                  <ItemText>{name}</ItemText>
                </Item>
              ))}
            </Viewport>
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
