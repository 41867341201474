import { Link } from "@/components/core";
import logoLabs from "@/assets/logos/logoLabs.svg";
import classes from "./footer.module.css";

const year = new Date().getFullYear();

export function Footer() {
  return (
    <footer className={classes.wrapper}>
      <div className={classes.content}>
        <Link variant="ghost" to="/politica-de-privacidade">
          Políticas de privacidade Havan
        </Link>
        <div>
          <p>
            HAVAN S.A. | CNPJ: 79.379.491.0008-50 | Rod. Antônio Heil, 250 -
            Centro II, Brusque - SC, 88353-100
          </p>
          <p>© {year} - Todos os direitos reservados</p>
        </div>
      </div>
      <img src={logoLabs} height={40} alt="Havan Labs" />
    </footer>
  );
}
