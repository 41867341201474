import { useEffect } from "react";
import classNames from "classnames";
import {
  CheckCircle,
  Close,
  CloseCircle,
  Info,
} from "@havan/paladinos-react-icons";
import { useAlert } from "./hook";
import { AlertProps } from "./types";
import classes from "./alert.module.css";

export function Alert({ id, variant, title, description }: AlertProps) {
  const alert = useAlert();

  const alertClasses = classNames(classes.wrapper, {
    [classes.alert_alert]: variant === "alert",
    [classes.alert_error]: variant === "error",
    [classes.alert_info]: variant === "info",
    [classes.alert_success]: variant === "success",
  });

  useEffect(() => {
    const timer = setTimeout(() => alert.remove(id), 5000);

    return () => clearTimeout(timer);
  });

  return (
    <div className={alertClasses}>
      {(variant === "alert" || variant === "info") && <Info />}
      {variant === "error" && <CloseCircle />}
      {variant === "success" && <CheckCircle />}

      <div className={classes.text_wrapper}>
        {title && <p>{title}</p>}
        {description && <p>{description}</p>}
      </div>
      <button onClick={() => alert.remove(id)}>
        <Close />
      </button>
    </div>
  );
}
