import { Fragment } from "react";
import { Coin } from "@havan/paladinos-react-icons";
import { Spinner } from "@/components/spinner";
import { Parcelamento } from "@/services/renegociacao/types";
import { formatMoney } from "@/utils/formatters";
import { Badge } from "@/components/core";
import classes from "./summary_agreement.module.css";

interface Props {
  parcela?: Parcelamento;
  valorDivida?: number;
  expiresAt?: string;
}

export function SummaryAgreement({ parcela, valorDivida, expiresAt }: Props) {
  const discountPercentage =
    parcela && !!valorDivida
      ? Math.abs(Math.ceil((parcela.valorDiferenca / valorDivida) * 100))
      : 0;
  const showDiscount = !!parcela && valorDivida && parcela.valorDiferenca < 0;

  if (parcela) {
    return (
      <div className={classes.wrapper}>
        {showDiscount && (
          <Fragment>
            <div className={classes.debt}>
              <p>Valor da dívida</p>
              <p>{formatMoney(valorDivida)}</p>
            </div>
            <div className={classes.discount}>
              <Badge color="positive">Desconto {discountPercentage}%</Badge>
              <p>{formatMoney(Math.abs(parcela.valorDiferenca))}</p>
            </div>
            <hr />
          </Fragment>
        )}
        <div className={classes.total}>
          <div>
            <Coin />
            <p>Valor total à vista</p>
          </div>
          <p>{!!valorDivida && formatMoney(parcela?.valorTotal)}</p>
        </div>
        {!!expiresAt && (
          <div className={classes.debt}>
            <p>Data de vencimento</p>
            <p>{new Date(expiresAt).toLocaleDateString()}</p>
          </div>
        )}
      </div>
    );
  }

  return <Spinner />;
}
