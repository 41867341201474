import { LogoLettering } from "@havan/paladinos-react-icons";
import { ModalSignOut } from "../../modal-sign-out";
import classes from "./private_navbar.module.css";

export function PrivateNavbar() {
  return (
    <nav className={classes.wrapper}>
      <div className={classes.content}>
        <LogoLettering />

        <ModalSignOut />
      </div>
    </nav>
  );
}
