import { Fragment, useState } from "react";
import * as RadixDialog from "@radix-ui/react-dialog";
import { AxiosError } from "axios";
import { CalendarPositive, Coin, Copy } from "@havan/paladinos-react-icons";
import { Pagamento } from "@/services/pagamento";
import { Pix } from "@/services/pagamento/types";
import { formatMoney } from "@/utils/formatters";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { Spinner } from "@/components/spinner";
import { Button, Dialog } from "@/components/core";
import classes from "./payment_commom.module.css";

export function ModalPix() {
  const [pix, setPix] = useState<Pix>();
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const alert = useAlert();

  function fetchPix() {
    setIsFetching(true);
    setIsOpen(true);
    Pagamento.pix()
      .then((response) => setPix(response.data.result))
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response?.data.errors) {
          const { errors } = error.response.data;
          errors.forEach((message) => {
            alert.alert("Pix", message);
          });
        }
        setIsOpen(false);
      })
      .finally(() => setIsFetching(false));
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <Button
          id="abre-modal-pix"
          color="positive"
          size="large"
          onClick={() => fetchPix()}
        >
          Pix
        </Button>
      </Dialog.Trigger>
      <RadixDialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Title>Pagar com Pix</Dialog.Title>
          <div className={classes.instruction_wrapper}>
            {!isFetching && !!pix && isOpen ? (
              <Fragment>
                <div className={classes.instruction}>
                  <p>
                    Aproxime o celular da tela para fazer a leitura do QR Code
                  </p>
                  <img
                    src={`data:image/png;base64,${pix.qrCodeBase64}`}
                    width={275}
                    alt="QrCode"
                  />
                </div>
                <Button
                  id="copia-codigo-pix"
                  onClick={() =>
                    navigator.clipboard.writeText(pix.copiaCola ?? "")
                  }
                  size="large"
                >
                  <Copy /> Copiar código Pix
                </Button>
                <div className={classes.payment_info}>
                  <div className={classes.payment_info_line}>
                    <div>
                      <Coin />
                      <p>Valor</p>
                    </div>
                    <p>{formatMoney(pix.valor)}</p>
                  </div>
                  <div className={classes.payment_info_line}>
                    <div>
                      <CalendarPositive />
                      <p>Vencimento</p>
                    </div>
                    <p>{new Date(pix.dataVencimento).toLocaleDateString()}</p>
                  </div>
                </div>
              </Fragment>
            ) : (
              <Spinner />
            )}
          </div>

          <Dialog.CloseIcon
            aria-label="Close"
            onClick={() => setIsOpen(false)}
          />
        </Dialog.Content>
      </RadixDialog.Portal>
    </Dialog.Root>
  );
}
