import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CalendarPositive, Coin } from "@havan/paladinos-react-icons";
import { Box } from "@/components/Box";
import { PAGE_TITLE } from "@/utils/constants";
import { formatMoney } from "@/utils/formatters";
import { Container } from "../components/container";
import { ModalBoleto } from "./modal-ticket";
import { ModalPix } from "./modal-pix";
import { PaymentState } from "../../negociar/payment/types";
import classes from "./payment.module.css";

export function Pagamento() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const payment = state as PaymentState;

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Realizar pagamento`;

    if (!payment) {
      navigate("/");
    }
  }, []);

  return (
    <Container>
      <Box.Root>
        <Box.Header>Realizar pagamento</Box.Header>
        <div className={classes.description}>
          <div>
            <Coin />
            <p>Valor da entrada</p>
          </div>
          <p>{formatMoney(payment?.total)}</p>
        </div>
        <div className={classes.description}>
          <div>
            <CalendarPositive />
            <p>Data de vencimento</p>
          </div>
          <p>{new Date(payment?.expiresAt).toLocaleDateString()}</p>
        </div>
        <div className={classes.actions}>
          <ModalPix />
          <ModalBoleto />
        </div>
      </Box.Root>
    </Container>
  );
}
