import {
  ChartPie,
  CheckCircle,
  Invoice,
  MobileShield,
} from "@havan/paladinos-react-icons";
import { Link } from "@/components/core";
import styles from "./deal.module.css";

export function Deal() {
  return (
    <section id="beneficios" className={styles.wrapper}>
      <div className={styles.how_works}>
        <div className={styles.how_works_inner}>
          <img
            src="/images/liberdade-cartao.webp"
            alt="Liberdade segurando o Cartão Havan"
          />
          <div className={styles.how_works_content}>
            <header>
              <h2>Como funciona a negociação Havan?</h2>
              <p>Em 3 passos você negocia sua dívida e limpa seu nome</p>
            </header>

            <ul className={styles.how_works_list}>
              <li className={styles.how_works_item}>
                <CheckCircle />
                <p>Informe seus dados e confira sua dívida</p>
              </li>

              <li className={styles.how_works_item}>
                <CheckCircle />
                <p>Escolha a melhor opção pra você e conclua o acordo</p>
              </li>

              <li className={styles.how_works_item}>
                <CheckCircle />
                <p>Agora é só pagar a entrada no boleto ou no pix</p>
              </li>
            </ul>
            <p>
              Você pode acompanhar o andamento e as parcelas do seu acordo pelo
              Aplicativo Havan
            </p>
          </div>
        </div>
        <Link
          variant="secondary"
          to="https://havan.page.link/nYJz"
          target="_blank"
        >
          Baixe o aplicativo
        </Link>
      </div>

      <div className={styles.deal_content}>
        <header>
          <h2>Negocie a dívida do seu Cartão Havan</h2>
          <p>O melhor desconto pra você está aqui!</p>
        </header>
        <div className={styles.deal_content_grid}>
          <div className={styles.deal_content_card}>
            <Invoice />
            <h3>Ofertas especiais para você</h3>
            <p>
              Faça uma simulação para negociar sua dívida direto com a Havan e
              garanta o melhor desconto
            </p>
          </div>
          <div className={styles.deal_content_card}>
            <ChartPie />
            <h3>Escolha o melhor parcelamento</h3>
            <p>
              Veja as opções de parcelamento disponíveis e escolha a melhor pra
              você
            </p>
          </div>
          <div className={styles.deal_content_card}>
            <MobileShield />
            <h3>Rápido, simples e seguro</h3>
            <p>
              Mais praticidade, faça tudo online. Em poucos passos você negocia
              sua dívida com a Havan
            </p>
          </div>
        </div>
        <Link to="/negociar" size="large">
          Simular acordo
        </Link>
      </div>
    </section>
  );
}
