import type { PropsWithChildren } from "react";
import type { CheckboxProps } from "@radix-ui/react-checkbox";
import { Indicator, Root } from "@radix-ui/react-checkbox";
import { Check } from "@havan/paladinos-react-icons";
import styles from "./checkbox.module.css";

type Props = CheckboxProps & PropsWithChildren;

export function Checkbox({ children, ...rest }: Props) {
  return (
    <div className={styles.wrapper}>
      <Root {...rest} className={styles.root}>
        <Indicator className={styles.indicator}>
          <Check />
        </Indicator>
      </Root>
      {children}
    </div>
  );
}
