import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Coin, Invoice } from "@havan/paladinos-react-icons";
import { AxiosError } from "axios";
import { Box } from "@/components/Box";
import { Step } from "@/components/Step";
import { Terms } from "@/components/Terms";
import { formatMoney } from "@/utils/formatters";
import { Renegociacao } from "@/services/renegociacao";
import { PAGE_TITLE } from "@/utils/constants";
import { useSimulation } from "@/providers/Simulation/hook";
import { SimulationStep } from "@/enums/SimulationStep";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { PaymentState } from "../../payment/types";
import { Container } from "../../components/container";
import { Badge, Button } from "@/components/core";
import classes from "./payment_installment_summary.module.css";
import { Loader } from "@/assets/icons/Loader";

export function PaymentInInstallmentsSummary() {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isClosingDeal, setIsClosingDeal] = useState(false);
  const { bestOffer, installments, installmentOptions, setSimulationStep } =
    useSimulation();
  const navigate = useNavigate();
  const alert = useAlert();

  const parcela = installmentOptions?.parcelamento.find(
    (parcela) => parcela.parcelas === installments
  );

  const discount =
    parcela?.valorTotal && !!bestOffer
      ? Math.abs(
          Math.ceil((parcela.valorDiferenca / bestOffer.valorDivida) * 100)
        )
      : undefined;

  const showDiscount = !!parcela ? parcela.valorDiferenca < 0 : false;

  useLayoutEffect(() => {
    if (parcela) {
      document
        .getElementById("fechar-acordo-parcelado")!
        .addEventListener("click", function () {
          (window as any).gtag("event", "fechar-acordo-parcelado", {
            value: bestOffer?.valorDivida,
            parcelas: parcela.parcelas,
            valorEntrada: parcela.valorEntrada,
            valorParcela: parcela.valorParcela,
            currency: "BRL",
            transaction_id: parcela.hash,
          });
        });
    }
  });

  function efetivarAcordo() {
    if (parcela) {
      const payment = {
        total: parcela.valorEntrada,
        expiresAt: parcela.dataVencimento,
      } as PaymentState;
      setIsClosingDeal(true);
      Renegociacao.efetivarAcordo({ hash: parcela.hash })
        .then((response) => {
          navigate("/pagamento", { state: payment });
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response?.data.errors) {
            error.response.data.errors.forEach((errorMessage) => {
              alert.alert("Atenção", errorMessage);
            });
          }
        })
        .finally(() => setIsClosingDeal(false));
    }
  }

  function stepBack() {
    setSimulationStep(SimulationStep.PaymentInInstallments);
  }

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Resumo do parcelamento`;
  });

  return (
    <Container>
      <Step
        handleStepBack={() => stepBack()}
        title="Resumo da Negociação"
        current={4}
        max={4}
      />

      <Box.Root>
        <Box.Header>
          <Invoice />
          Acordo
        </Box.Header>

        {showDiscount && (
          <Fragment>
            <div className={classes.debt}>
              <p>Valor da dívida</p>
              <p>
                {!!installmentOptions?.valorDivida &&
                  formatMoney(installmentOptions.valorDivida)}
              </p>
            </div>

            <div className={classes.discount}>
              <Badge color="positive">
                Desconto {Number.isInteger(discount) && discount}%
              </Badge>

              <p>
                {!!parcela && formatMoney(Math.abs(parcela.valorDiferenca))}
              </p>
            </div>
          </Fragment>
        )}
        <div className={classes.total}>
          <div>
            <Coin />
            <p>Valor total</p>
          </div>

          <p>{!!parcela && formatMoney(parcela.valorTotal)}</p>
        </div>
      </Box.Root>

      <Box.Root className={classes.payment_box}>
        <Box.Header>
          <Invoice /> Pagamento
        </Box.Header>

        <div className={classes.line}>
          <p>Data de vencimento da entrada</p>
          <p>
            {!!parcela && new Date(parcela.dataVencimento).toLocaleDateString()}
          </p>
        </div>
        <div className={classes.line}>
          <p>Valor de entrada</p>
          <p>{!!parcela && formatMoney(parcela.valorEntrada)}</p>
        </div>
        <div className={classes.line}>
          <p>Parcelas</p>
          {!!parcela && (
            <p>
              {parcela.parcelas - 1}x de{" "}
              {formatMoney(parcela?.valorParcela ?? 0)}
            </p>
          )}
        </div>
        <p className={classes.box_info}>
          As parcelas serão adicionadas a fatura do seu Cartão Havan, o
          vencimento será o mesmo da fatura
        </p>
      </Box.Root>

      <Terms
        isTermsChecked={isTermsChecked}
        setTermsChecked={setIsTermsChecked}
      />

      <Button
        id="fechar-acordo-parcelado"
        onClick={() => efetivarAcordo()}
        disabled={!isTermsChecked || isClosingDeal}
        size="large"
        spinner={isClosingDeal}
      >
        {isClosingDeal ? (
          <Fragment>
            <Loader />
            Fechando acordo...
          </Fragment>
        ) : (
          "Confirmar"
        )}
      </Button>
    </Container>
  );
}
