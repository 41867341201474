import { SimulationStep } from "@/enums/SimulationStep";
import { useSimulation } from "@/providers/Simulation/hook";
import { PaymentInCash } from "./cash-payment";
import { BestOffer } from "./best-offer";
import { PaymentInInstallments } from "./installment-payment/down-payment";
import { PaymentInInstallmentsSummary } from "./installment-payment/summary";

export function Negociar() {
  const { simulationStep } = useSimulation();

  if (simulationStep === SimulationStep.BestOffer) {
    return <BestOffer />;
  }

  if (simulationStep === SimulationStep.PaymentInCash) {
    return <PaymentInCash />;
  }

  if (simulationStep === SimulationStep.PaymentInInstallments) {
    return <PaymentInInstallments />;
  }

  if (simulationStep === SimulationStep.PaymentInInstallmentsSummary) {
    return <PaymentInInstallmentsSummary />;
  }

  return null;
}
