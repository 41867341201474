import { HTMLAttributes, PropsWithChildren } from "react";
import classNames from "classnames";
import classes from "./badge.module.css";

type Color = "default" | "positive" | "negative" | "warning" | "neutral";
type Size = "small" | "medium" | "large";

interface Props extends HTMLAttributes<HTMLSpanElement> {
  color?: Color;
  size?: Size;
}

export function Badge({
  color = "default",
  size = "medium",
  children,
  className,
  ...rest
}: PropsWithChildren<Props>) {
  const badgeClasses = classNames(className, classes.base, {
    [classes.color_default]: color === "default",
    [classes.color_positive]: color === "positive",
    [classes.color_negative]: color === "negative",
    [classes.color_warning]: color === "warning",
    [classes.color_neutral]: color === "neutral",
    [classes.size_small]: size === "small",
    [classes.size_medium]: size === "medium",
    [classes.size_large]: size === "large",
  });

  return (
    <span className={badgeClasses} {...rest}>
      {children}
    </span>
  );
}
