import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "@/components/header";

export function LayoutPagamento() {
  return (
    <Fragment>
      <Header>
        <h1>Parabéns por concluir o acordo</h1>
        <p>Agora só falta realizar o pagamento</p>
      </Header>

      <Outlet />
    </Fragment>
  );
}
