import { ArrowLeft } from "@havan/paladinos-react-icons";
import classes from "./step.module.css";

interface Props {
  title: string;
  current: number;
  max: number;
  handleStepBack?: () => void;
}

export function Step({ title, current, max, handleStepBack }: Props) {
  return (
    <div className={classes.wrapper}>
      {!!handleStepBack && (
        <div className={classes.action} onClick={handleStepBack}>
          <ArrowLeft />
        </div>
      )}
      <div className={classes.info}>
        <header>
          <p>{title}</p>
          <p>
            {current} de {max}
          </p>
        </header>
        <progress value={current} max={max} />
      </div>
    </div>
  );
}
