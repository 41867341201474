import type { AnchorHTMLAttributes } from "react";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import classNames from "classnames";
import classes from "../button/button.module.css";

import { ButtonProps } from "../button";

type AnchorProps = Pick<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  "className" | "target" | "rel" | "download"
>;

type CustomButtonProps = Pick<
  ButtonProps,
  "color" | "variant" | "size" | "spinner"
>;

export type LinkProps = RouterLinkProps & AnchorProps & CustomButtonProps;

export function Link({
  color = "default",
  variant = "primary",
  size = "medium",
  spinner,
  children,
  className,
  ...rest
}: PropsWithChildren<LinkProps>) {
  const buttonClasses = classNames(className, classes.base_button, {
    [classes.color_default]: color === "default",
    [classes.color_positive]: color === "positive",
    [classes.color_negative]: color === "negative",
    [classes.color_white]: color === "white",
    [classes.variant_primary]: variant === "primary",
    [classes.variant_secondary]: variant === "secondary",
    [classes.variant_ghost]: variant === "ghost",
    [classes.size_small]: size === "small",
    [classes.size_medium]: size === "medium",
    [classes.size_large]: size === "large",
    [classes.spinner]: spinner,
  });

  return (
    <RouterLink className={buttonClasses} {...rest}>
      {children}
    </RouterLink>
  );
}
