import { Link as RouterLink } from "react-router-dom";
import {
  IllustrationBillDebtMobile,
  IllustrationHandCard,
  IllustrationHavanAccount,
} from "@havan/paladinos-react-icons";
import { QrCodeApp } from "@/assets/icons/QrCodeApp";
import { Link } from "@/components/core";
import styles from "./deal_in_app.module.css";

export function DealInApp() {
  const LINK_APP = "https://havan.page.link/nYJz";

  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        <img
          src="/images/negociacao-aplicativo-havan.webp"
          alt="Tela do Cartão Havan no aplicativo onde é possível acessar fatura e acompanhar as parcelas do acordo de negociação Havan"
        />
        <div className={styles.info}>
          <h2>Negocie sua dívida pelo aplicativo Havan</h2>
          <div className={styles.info_cards}>
            <div className={styles.info_card}>
              <IllustrationBillDebtMobile />
              <h3>Mais praticidade</h3>
              <p>Acompanhe as parcelas da negociação pelo aplicativo</p>
            </div>
            <div className={styles.info_card}>
              <IllustrationHandCard />
              <h3>Limpe seu nome</h3>
              <p>Escolha o parcelamento e a forma de pagamento</p>
            </div>
            <div className={styles.info_card}>
              <IllustrationHavanAccount />
              <h3>Fácil e seguro</h3>
              <p>Mais comodidade e segurança</p>
            </div>
          </div>
          <div className={styles.qr_code_wrapper}>
            <QrCodeApp />
            <p>
              <span>Aponte a câmera do celular para o QR Code e </span>
              <RouterLink to={LINK_APP} target="_blank" reloadDocument>
                Baixe o aplicativo
              </RouterLink>
            </p>
          </div>
          <Link to={LINK_APP} size="large" target="_blank" reloadDocument>
            Baixar aplicativo
          </Link>
        </div>
      </div>
    </section>
  );
}
