import { useLocation } from "react-router-dom";
import { ArrowLeft } from "@havan/paladinos-react-icons";
import { Button, Dialog } from "@/components/core";
import { useAuth } from "@/auth/hook";
import classes from "./modal_sign_out.module.css";

export function ModalSignOut() {
  const { pathname } = useLocation();
  const { logout } = useAuth();

  const { buttonText, description } =
    pathname === "/pagamento"
      ? {
          description: "Ao sair, o pagamento ficará pendente",
          buttonText: "Realizar o pagamento",
        }
      : {
          description: "Ao sair, todo progresso da simulação será perdido",
          buttonText: "Continuar minha negociação",
        };

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button variant="ghost">
          <ArrowLeft /> Sair da negociação
        </Button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content>
          <Dialog.Title>
            Tem certeza que deseja sair da negociação?
          </Dialog.Title>

          <Dialog.Description>{description}</Dialog.Description>

          <div className={classes.actions}>
            <Button
              id="sair"
              variant="secondary"
              color="negative"
              onClick={logout}
              size="large"
            >
              Sim, desejo sair
            </Button>
            <Dialog.Close asChild>
              <Button size="large">{buttonText}</Button>
            </Dialog.Close>
          </div>

          <Dialog.CloseIcon />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
