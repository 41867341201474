import { Fragment, useEffect } from "react";
import { PAGE_TITLE } from "@/utils/constants";
import { Hero } from "./hero";
import { Deal } from "./deal";
import { WhyNegociate } from "./why-negociate";
import { OftenDoubts } from "./often-doubts";
import { DealInApp } from "./deal-in-app";

export function LandingPage() {
  useEffect(() => {
    document.title = PAGE_TITLE;

    (window as any).gtag("event", "Home");
  });

  return (
    <Fragment>
      <Hero />
      <Deal />
      <WhyNegociate />
      <OftenDoubts />
      <DealInApp />
    </Fragment>
  );
}
