import type { DialogCloseProps } from "@radix-ui/react-dialog";
import { Close as Primitive } from "@radix-ui/react-dialog";
import { Close } from "@havan/paladinos-react-icons";
import { Button } from "@/components/core";
import classes from "./dialog_close_icon.module.css";

export function CloseIcon(props: DialogCloseProps) {
  return (
    <Primitive className={classes.wrapper} asChild {...props}>
      <Button variant="ghost" aria-label="Close">
        <Close />
      </Button>
    </Primitive>
  );
}
