import { useEffect, useState } from "react";
import { addDays, eachDayOfInterval, formatISO } from "date-fns";
import { AxiosError } from "axios";
import { Invoice } from "@havan/paladinos-react-icons";
import { Box } from "@/components/Box";
import { Step } from "@/components/Step";
import { SimulationStep } from "@/enums/SimulationStep";
import { formatMoney } from "@/utils/formatters";
import { Container } from "../../components/container";
import { PAGE_TITLE } from "@/utils/constants";
import { useSimulation } from "@/providers/Simulation/hook";
import { Renegociacao } from "@/services/renegociacao";
import { ResponseError } from "@/services/types";
import { useAlert } from "@/components/Alert/hook";
import { Button, Input } from "@/components/core";
import { Item, Select } from "@/components/core/select";
import { InstallmentChooser } from "../components/installment-chooser";
import classes from "./down_payment.module.css";

export function PaymentInInstallments() {
  const {
    bestOffer,
    installments,
    setInstallments,
    setInstallmentOptions,
    setSimulationStep,
    installmentOptions,
  } = useSimulation();

  const now = new Date();

  const parcela = installmentOptions?.parcelamento.find(
    (parcela) => parcela.parcelas === installments
  );

  const [valorEntrada, setValorEntrada] = useState(parcela?.valorEntrada ?? 0);
  const [expiresAt, setExpiresAt] = useState(
    formatISO(now, { representation: "date" })
  );
  const [isFetchingInstallmentOptions, setIsFetchingInstallmentOptions] =
    useState(false);

  const alert = useAlert();

  const expireAtOptions: Item[] = eachDayOfInterval({
    start: now,
    end: addDays(now, 5),
  }).map((day) => ({
    name: day.toLocaleDateString(),
    value: formatISO(day, { representation: "date" }),
  }));

  const valorMinimo = bestOffer?.valorMinimoParcela ?? 0;

  const helpTextOutOfBounds =
    valorEntrada < valorMinimo
      ? `O valor mínimo é de ${formatMoney(valorMinimo)}`
      : `O valor maximo é de ${formatMoney(bestOffer!.valorDivida)}`;

  const helpTextInputEntry =
    (!!bestOffer?.valorDivida &&
      valorEntrada <= bestOffer.valorDivida &&
      valorEntrada >= valorMinimo) ||
    valorEntrada === 0
      ? "A entrada é considerada uma parcela"
      : helpTextOutOfBounds;

  const downPaymentHelperText =
    installmentOptions?.parcelamento.length === 0
      ? "Não existem opções de parcelamento para este valor de entrada"
      : helpTextInputEntry;

  const isHelpTextEntryError =
    !!bestOffer?.valorDivida &&
    (valorEntrada < valorMinimo ||
      valorEntrada > bestOffer?.valorDivida ||
      installmentOptions?.parcelamento.length === 0) &&
    !!valorEntrada;

  const isHelpTextEntrySuccess =
    valorEntrada >= valorMinimo &&
    !!bestOffer?.valorDivida &&
    bestOffer?.valorDivida > valorEntrada;
  const canShowInstallment = valorEntrada > 0 && !isHelpTextEntryError;

  const cantConfirm =
    expiresAt.length === 0 ||
    valorEntrada <= 0 ||
    isHelpTextEntryError ||
    installments <= 1 ||
    !parcela;

  const canFetchInstallments =
    !!bestOffer?.valorDivida &&
    valorEntrada >= valorMinimo &&
    valorEntrada <= bestOffer.valorDivida;

  useEffect(() => {
    document.title = `${PAGE_TITLE} | Entrada do pagamento parcelado`;
  }, []);

  useEffect(() => {
    if (canFetchInstallments) {
      setIsFetchingInstallmentOptions(true);
      const timer = setTimeout(() => {
        Renegociacao.opcoesParcelamento({
          DataPrimeiraParcela: expiresAt,
          ValorEntrada: valorEntrada,
          ValorMinimoParcela: valorMinimo,
        })
          .then((response) => {
            setInstallmentOptions(response.data.result);
            setInstallments(response.data.result.parcelamento[0].parcelas);
          })
          .catch((error: AxiosError<ResponseError>) => {
            if (error.response?.data.errors) {
              error.response.data.errors.forEach((errorMessage) => {
                alert.alert("Atenção", errorMessage);
              });
            }
          })
          .finally(() => setIsFetchingInstallmentOptions(false));
      }, 1500);

      return () => {
        setIsFetchingInstallmentOptions(false);
        clearTimeout(timer);
      };
    }
  }, [valorEntrada, valorMinimo, expiresAt]);

  return (
    <Container>
      <Step
        handleStepBack={() => setSimulationStep(SimulationStep.BestOffer)}
        title="Valor de entrada"
        current={3}
        max={4}
      />
      <Box.Root>
        <Box.Header>
          <Invoice />
          Valor de entrada
        </Box.Header>
        <Select
          label="Data de vencimento da entrada"
          value={expiresAt}
          defaultValue={expiresAt}
          onValueChange={setExpiresAt}
          itens={expireAtOptions}
        />

        <Input
          label="Valor de entrada"
          min={valorMinimo}
          max={bestOffer?.valorDivida}
          isError={isHelpTextEntryError}
          step="0.01"
          isSuccess={isHelpTextEntrySuccess}
          type="number"
          value={valorEntrada ? valorEntrada : ""}
          helperText={downPaymentHelperText}
          onChange={(event) => {
            setValorEntrada(Number(event.target.value));
          }}
          isMoney
        />

        {canShowInstallment && (
          <InstallmentChooser
            isLoading={isFetchingInstallmentOptions}
            hasErrors={!isHelpTextEntrySuccess}
          />
        )}
      </Box.Root>
      <div className={classes.actions}>
        <Button
          onClick={() =>
            setSimulationStep(SimulationStep.PaymentInInstallmentsSummary)
          }
          disabled={cantConfirm}
          size="large"
        >
          Confirmar
        </Button>
        <Button
          variant="secondary"
          onClick={() => setSimulationStep(SimulationStep.BestOffer)}
          size="large"
        >
          Voltar para a melhor oferta
        </Button>
      </div>
    </Container>
  );
}
