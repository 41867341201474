import { Dispatch, SetStateAction } from "react";
import { Admission } from "@havan/paladinos-react-icons";
import { useSimulation } from "@/providers/Simulation/hook";
import { Box } from "../Box";
import { Checkbox } from "../core";
import classes from "./terms.module.css";

interface Props {
  isTermsChecked: boolean;
  setTermsChecked: Dispatch<SetStateAction<boolean>>;
}

export function Terms({ isTermsChecked, setTermsChecked }: Props) {
  const { installments } = useSimulation();
  return (
    <Box.Root>
      <Box.Header>
        <Admission height={32} width={32} />
        Termos do acordo
      </Box.Header>
      <ol className={classes.list}>
        <li>Os valores informados nesse acordo são válidos por 5 dias</li>
        <li>
          Caso não realize o pagamento da entrada dentro do prazo, será
          cancelado e você terá que fazer um novo acordo
        </li>
        {installments > 1 && (
          <li>
            As demais parcelas serão adicionados no vencimento da fatura do seu
            Cartão Havan
          </li>
        )}
      </ol>

      <Checkbox
        defaultChecked
        id="termos"
        checked={isTermsChecked}
        onCheckedChange={(value) => setTermsChecked(!!value)}
      >
        <label htmlFor="termos" className={classes.label}>
          Estou ciente e de acordo com os termos
        </label>
      </Checkbox>
    </Box.Root>
  );
}
